<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        :width="700"
        @input="$emit('input', $event)">
        <div>
            <v-form
                v-if="!loading"
                ref="form"
                v-model="valid">
                <v-card-text>
                    <v-container>
                        <h2>Customize Appearance</h2>

                        <p>
                            This form allows you to customize the experience for your White
                            Labeled MarketAnalyzer. Each section of this panel will correspond
                            with an area within your MarketAnalyzer that can be customized to
                            match your agencies branding.
                        </p>

                        <h2>Page Title</h2>

                        <p>Input the page title that will be displayed on your MarketAnalyzer page</p>

                        <v-text-field
                            v-model="title"
                            style="width: 60%; padding-bottom: 20px"
                            label="PAGE TITLE"
                            maxlength="50"
                            single-line
                            hide-details
                            class="styled-field" />

                        <h2>Favicon</h2>

                        <p>Upload a favicon icon that will be used on your customized MarketAnalyzer </p>

                        <logo-file-input
                            :saved-logo="savedFavicon"
                            upload-type="favicon"
                            @fileRemovedEventListener="removeFile"
                            @faviconListener="setFaviconObject" />
                        <br>

                        <h2>Font customization</h2>

                        <p>
                            Select a font below that will be used throughout your customized
                            MarketAnalyzer.
                        </p>

                        <v-autocomplete
                            v-model="selectedFontHeader"
                            style="width: 60%"
                            label="Select a font for headers"
                            class="mb-3 styled-field"
                            :items="availableFonts"
                            item-text="name"
                            item-value="value" />

                        <v-autocomplete
                            v-model="selectedFontContent"
                            style="width: 60%"
                            label="Select a font for content"
                            class="mb-3 styled-field"
                            :items="availableFonts"
                            item-text="name"
                            item-value="value" />
                        <a>Don't see the font you want? Click here to request it</a>

                        <br><br>

                        <h2>Color scheme</h2>

                        <p>
                            Select a Primary and Secondary color below that will be displayed
                            on your customized MarketAnalyzer. Once a Primary and Secondary
                            color have been selected, select the Generate Color Schemes button
                            so our system can generate the colors need for your customized
                            MarketAnalyzer.
                        </p>

                        <div
                            v-if="!hasColorSchema"
                            class="row">
                            <div class="column">
                                <div>
                                    <color-picker-input
                                        :color="primaryColor"
                                        emitter-key="primaryColorListener"
                                        title="Select a primary color"
                                        @primaryColorListener="setPrimaryColor" />
                                </div>
                            </div>
                            <div class="column">
                                <div>
                                    <color-picker-input
                                        :color="secondaryColor"
                                        emitter-key="secondaryColorListener"
                                        title="Select a secondary color"
                                        @secondaryColorListener="setSecondaryColor" />
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="!hasColorSchema"
                            :class="[!colorsAreSetted ? 'disabled' : 'active', 'button']"
                            @click="generateColorScheme">
                            GENERATE COLORS SCHEME
                        </div>

                        <div
                            v-if="hasColorSchema"
                            class="row">
                            <div class="column">
                                <div class="column-child">
                                    <div>
                                        <palette
                                            title="Primary color scheme"
                                            :schemes="primaryColorScheme" />
                                        <span class="hex-hint"> HEX: {{ primaryColor }} </span>
                                    </div>
                                    <div>
                                        <edit-scheme-button
                                            emitter-key="primaryColorListener"
                                            @primaryColorListener="setPrimaryColorScheme" />
                                    </div>
                                    <div />
                                </div>
                            </div>
                            <div class="column">
                                <div class="column-child">
                                    <div>
                                        <palette
                                            title="Secondary color scheme"
                                            :schemes="secondaryColorScheme" />
                                        <span class="hex-hint"> HEX: {{ secondaryColor }} </span>
                                    </div>
                                    <div>
                                        <edit-scheme-button
                                            emitter-key="secondaryColorListener"
                                            @secondaryColorListener="setSecondaryColorScheme" />
                                    </div>
                                    <div />
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <h2>Logo</h2>

                        <p>
                            Select and upload a logo that will be used to display on the pages
                            throughout your customized MarketAnalyzer.
                        </p>

                        <logo-file-input
                            :saved-logo="savedLogo"
                            upload-type="logo"
                            @fileRemovedEventListener="removeFile"
                            @logoListener="setLogoObject" />

                        <h2 class="mt-5">
                            Whitelabel landing page URLs
                        </h2>

                        <p>Input a URL for a landing page where customers can learn more about how to activate the MarketAnalyzer Ads.</p>

                        <v-text-field
                            v-model="landingPage"
                            label="AGENCY LANDING PAGE URL"
                            :rules="urlRules"
                            class="styled-field" />

                        <h2 class="mt-5 mb-3">
                            Channel "Why" landing page URLs
                        </h2>

                        <v-text-field
                            v-model="facebookUrl"
                            label="WHY FACEBOOK URL"
                            :rules="urlRules"
                            class="styled-field mb-4" />
                        <v-text-field
                            v-model="tiktokUrl"
                            label="WHY TIKTOK URL"
                            :rules="urlRules"
                            class="styled-field mb-4" />
                        <v-text-field
                            v-model="pinterestUrl"
                            label="WHY PINTEREST URL"
                            :rules="urlRules"
                            class="styled-field mb-4" />
                        <v-text-field
                            v-model="snapchatUrl"
                            label="WHY SNAPCHAT URL"
                            :rules="urlRules"
                            class="styled-field mb-4" />
                        <v-text-field
                            v-if="currentAgency.id === AGENCY_DU"
                            v-model="googleUrl"
                            label="WHY GOOGLE URL"
                            :rules="urlRules"
                            class="styled-field mb-4" />
                    </v-container>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <v-btn
                        color="primary"
                        text
                        @click="saveWhiteLabelConfiguration">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-form>
            <div v-if="loading">
                <loader />
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import Palette from './Palette.vue';
import EditSchemeButton from './EditSchemeButton.vue';
import ColorPickerInput from './ColorPickerInput.vue';
import LogoFileInput from './LogoFileInput.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CreativeStudioFontManager } from '@/components/creative-studio/layered-designer/utils/font-manager';
import Loader from '../globals/Loader.vue';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { urlRules } from '@/helpers/validationRules';
import { AGENCY_DU } from '@/helpers/globals';

export default {
  components: {
    Palette,
    ColorPickerInput,
    EditSchemeButton,
    LogoFileInput,
    Loader,
    StyledSlideout,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      primaryColor: '',
      secondaryColor: '',
      primaryColorScheme: [],
      secondaryColorScheme: [],
      showFileInput: false,
      selectedFontHeader: null,
      selectedFontContent: null,
      agency: {},
      loading: true,
      logo: null,
      savedLogo: '',
      title: '',
      landingPage: '',
      facebookUrl: '',
      tiktokUrl: '',
      pinterestUrl: '',
      snapchatUrl: '',
      googleUrl: '',
      AGENCY_DU,
      uploadedLogo: {
        url: null,
      },
      favicon: null,
      savedFavicon: '',
      uploadedFavicon: {
        url: null,
      },
      logoHaschanged: false,
      faviconHasChanged: false,
      valid: false,
      urlRules,
    };
  },
  computed: {
    ...mapState({
      currentAgency: (state) => state.agency.currentAgency,
    }),
    ...mapGetters(['userIsAdmin', 'fontManager']),
    colorsAreSetted() {
      return this.primaryColor && this.secondaryColor;
    },
    hasColorSchema() {
      return (
        this.primaryColorScheme.length > 0 &&
        this.secondaryColorScheme.length > 0
      );
    },
    availableFonts() {
        const googleFonts = Array.from(this.fontManager?.getFontList().values()).filter(font => {
            if (!font.url) {
                return font;
            }
        });
        return googleFonts.map(font => {
            return font.family;
        }).sort();
    },
  },
  watch: {
    currentAgency: {
      handler() {
        this.loadAgency(), this.setSavedValues();
      },
    },
  },
  async mounted() {
    this.loadAgency();
    const fontManager = await CreativeStudioFontManager.initFontManager();
    this.setLayeredDesignerFontManager(fontManager);
    fontManager.preloadFonts();
  },
  methods: {
    ...mapActions([
        'setLayeredDesignerFontManager',
    ]),
    setPrimaryColor(value) {
      this.primaryColor = value;
    },
    setSecondaryColor(value) {
      this.secondaryColor = value;
    },
    setLogoObject(value) {
      this.logoHaschanged = true;
      this.logo = value;
    },
    setFaviconObject(value) {
      this.faviconHasChanged = true;
      this.favicon = value;
    },
    setPrimaryColorScheme(value) {
      this.primaryColor = value;
      this.primaryColorScheme = this.paletteGenerator(value);
    },
    setSecondaryColorScheme(value) {
      this.secondaryColor = value;
      this.secondaryColorScheme = this.paletteGenerator(value);
    },
    generateColorScheme() {
      this.primaryColorScheme = this.paletteGenerator(this.primaryColor);
      this.secondaryColorScheme = this.paletteGenerator(this.secondaryColor);
    },
    removeFile(value) {
        console.log(value);
      if (value === 'favicon') {
        this.favicon = null;
        this.savedFavicon = '';
        this.uploadedFavicon.url = null;
      } else {
        this.logo = null;
        this.savedLogo = '';
        this.uploadedLogo.url = null;
      }
    },
    paletteGenerator(color) {
      //  const lumonisity = [0, 10, 8, -0.3, -0.5, 13];

      const colorsLuminosity = [
        {
          darkness: 0,
          lumonisity: 1,
        },
        {
          darkness: 0,
          lumonisity: 0.5,
        },
        {
          darkness: 1,
          lumonisity: 0.8,
        },
        {
          darkness: -0.5,
          lumonisity: 1,
        },
        {
          darkness: -0.8,
          lumonisity: 1,
        },
        {
          darkness: 0,
          lumonisity: 0.1,
        },
      ];
      return colorsLuminosity.map((lum) => {
        color = String(color).replace(/[^0-9a-f]/gi, '');
        if (color.length < 6) {
          color =
            color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
        }
        const lumin = lum.darkness || 0;
        var rgb = '#',
          c,
          i;
        for (i = 0; i < 3; i++) {
          c = parseInt(color.substr(i * 2, 2), 16);
          c = Math.round(Math.min(Math.max(0, c + c * lumin), 255)).toString(
            16
          );
          rgb += ('00' + c).substr(c.length);
        }
        const [r, g, b] = rgb.match(/\w\w/g).map((x) => parseInt(x, 16));
        return `rgba(${r},${g},${b},${lum.lumonisity})`;
      });
    },
    setSavedValues() {
        if( this.agency.white_label_theme_config) {


      this.primaryColor =
        this.agency.white_label_theme_config.market_analyzer.primary_color;
      this.secondaryColor =
        this.agency.white_label_theme_config.market_analyzer.secondary_color;

      this.primaryColorScheme =
        this.agency.white_label_theme_config.market_analyzer.color_scheme;
      this.secondaryColorScheme =
        this.agency.white_label_theme_config.market_analyzer.color_scheme_secondary;

      this.selectedFontHeader =
        this.agency.white_label_theme_config.market_analyzer.heading_font;
      this.selectedFontContent =
        this.agency.white_label_theme_config.market_analyzer.content_font;

      this.logo = this.agency.white_label_theme_config.market_analyzer.logo;
      this.favicon =
        this.agency.white_label_theme_config.market_analyzer.favicon;
      this.savedLogo =
        this.agency.white_label_theme_config.market_analyzer.logo;
      this.savedFavicon =
        this.agency.white_label_theme_config.market_analyzer.favicon;
      this.title = this.agency.white_label_theme_config.market_analyzer.title;
      this.landingPage = this.agency.white_label_theme_config.market_analyzer.agency_landing_page_url;
      this.facebookUrl = this.agency.white_label_theme_config.market_analyzer.why_facebook_url;
      this.tiktokUrl = this.agency.white_label_theme_config.market_analyzer.why_tiktok_url;
      this.pinterestUrl = this.agency.white_label_theme_config.market_analyzer.why_pinterest_url;
      this.snapchatUrl = this.agency.white_label_theme_config.market_analyzer.why_snapchat_url;
      this.googleUrl = this.agency.white_label_theme_config.market_analyzer.why_google_url;
      }
    },
    async saveWhiteLabelConfiguration() {
      this.$refs.form.validate();
      if (!this.valid) { return }
      try {
        this.loading = true;
        if (this.logo && this.logoHaschanged) {
          if (typeof this.logo !== 'string') {
            const logoPath = await this.saveLogo('logo');
            this.uploadedLogo.url = logoPath.url;
          }
        }

        if (this.favicon && this.faviconHasChanged) {
          if (typeof this.favicon !== 'string') {
            const faviconPath = await this.saveLogo('favicon');
            this.uploadedFavicon.url = faviconPath.url;
          }
        }

        const data = {
          market_analyzer: {
            logo: this.logoHaschanged ? this.uploadedLogo.url : this.logo,
            favicon: this.faviconHasChanged
              ? this.uploadedFavicon.url
              : this.favicon,
            heading_font: this.selectedFontHeader,
            content_font: this.selectedFontContent,
            primary_color: this.primaryColor,
            secondary_color: this.secondaryColor,
            color_scheme: this.primaryColorScheme,
            color_scheme_secondary: this.secondaryColorScheme,
            title: this.title,
            agency_landing_page_url: this.landingPage,
            why_facebook_url: this.facebookUrl,
            why_tiktok_url: this.tiktokUrl,
            why_pinterest_url: this.pinterestUrl,
            why_snapchat_url: this.snapchatUrl,
            why_google_url: this.googleUrl,
          },
        };

        const request = {
          ...this.agency.white_label_theme_config,
          ...data,
        };

        await this.$http.put(`branding/${this.currentAgency.id}`, request);
        await this.loadAgency();
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async loadAgency() {
      try {
        this.loading = true;
        const response = await this.$http.put(
          `agencies/${this.currentAgency.id}`
        );
        this.agency = response.data.data;
        this.setSavedValues();
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async saveLogo(uploadType) {
      try {
        let formData = new FormData();
        if (uploadType === 'favicon') {
          formData.set('file', this.favicon);
        } else {
          formData.set('file', this.logo);
        }

        const response = await this.$http.post('files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    closeWhiteLabelDialog() {
      this.$emit('hideWhiteLabelDialog');
    },
  },
};
</script>
<style scoped>
.modal-header {
  background-color: #919fa8;
}

h2 {
  text-transform: capitalize;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.column-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button {
  border: solid;
  border-radius: 5px;
  padding: 4px;
  width: 40%;
  text-align: center;
}
.active {
  border-color: #51c1f7;
  color: #51c1f7;
  cursor: pointer;
}
.disabled {
  color: #ddd;
  border-color: #ddd;
}
.hex-hint {
  font-size: 12px;
}
</style>