
<template>
    <div v-if="chartDataIsLoaded">
        <apexchart
            type="line"
            :options="chartOptions"
            :series="series" />
    </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';
import moment from 'moment';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    metrics: Object,
  },
  data() {
    return {
         numberFormats: getNumberFormats(),
      numberFormatsAbbreviated: getNumberFormats(true),
      channelIndividuals: [],
      chartDataIsLoaded: false,
      series: [
        {
          name: 'Audience Size',
          type: 'column',
          data: [],
        },
        {
          name: 'Suggested Spend',
          type: 'line',
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0],
      },
      title: {
        text: 'Traffic Sources',
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 1,
        },
        markers: {
          size: 4,
          colors: ['#FFFFFF', '#FFFFFF'],
          strokeColors: ['#7FDDFE', '#E350B4'],
          strokeWidth: 1,
          fillOpacity: 0,
        },
        legend: {
          show: false,
        },
        colors: ['#00A2EA', '#E350B4'],
        grid: {
          strokeDashArray: 2,
        },
      },
      labels: [],
      yaxis: [
        {
          title: {
            text: 'Audience size',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Suggested spend',
          },
        },
      ],
    };
  },
  methods: {
    chartLabelFormatter(item) {
      if (item) {
        return numeral(item).format(this.numberFormatsAbbreviated.number);
      }
      return '0';
    },
    chartCurrencyLabelFormatter(item) {
      if (item) {
        return numeral(item).format(this.numberFormatsAbbreviated.currencyRound);
      }
      return '0';
    },
  },
  computed: {
    ...mapGetters(['userIsAdmin']),
  },
  watch: {
    metrics: {
      deep: true,
      handler() {
        this.channelIndividuals = this.metrics.channelsIndividual
          ? this.metrics.channelsIndividual
          : [];
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              type: 'datetime',
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: 'yyyy',
                  month: 'MMM',
                  day: 'dd',
                },
                style: {
                  colors: '#8f9ea6',
                },
              },
              tooltip: {
                enabled: false,
              },
            },
          },
          ...{
            yaxis: [
              {
                title: {
                  text: 'AUDIENCE SIZE',
                  style: {
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                    fontFamily: 'Open Sans',
                    color: '#4A4A4A',
                  },
                },
                labels: {
                  formatter: (value) => {
                    return this.chartLabelFormatter(value);
                  },
                },
              },
              {
                opposite: true,
                labels: {
                  formatter: (value) => {
                    return this.chartCurrencyLabelFormatter(value);
                  },
                },
                title: {
                  text: 'SUGGESTED SPEND',
                  style: {
                    textTransform: 'uppercase',
                    fontFamily: 'Open Sans',
                    color: '#4A4A4A',
                    fontWeight: 400,
                  },
                },
              },
            ],
          },
          ...{
            tooltip: {
              shared: false,
              custom(options) {
                const channels =
                  options.ctx.w.config.series[options.seriesIndex].description[
                    options.dataPointIndex
                  ];
                var dateObj = new Date(
                  options.w.globals.seriesX[options.seriesIndex][
                    options.dataPointIndex
                  ]
                ).toLocaleString('en-US', {
                  timeZone: 'America/New_York',
                });
                var tableRows = '';

                channels.map((item) => {
                  tableRows +=
                    '<tr>' +
                    '    <td>' +
                    item.channel +
                    '        ' +
                    '    </td>' +
                    '        <td>' +
                    numeral(item.audienceSize).format(getNumberFormats().number) +
                    '    </td>' +
                    '        <td>' +
                     numeral(item.suggestedMonthlySpend).format(getNumberFormats().currencyRound) +
                    '    </td>' +
                    '</tr>';
                });

                return (
                  '<div class="modal-header">' +
                  moment(dateObj).add(1, 'd').format('DD MMMM') +
                  '</div>' +
                  '<div class="modal-container">' +
                  '<table>' +
                  '    <thead>' +
                  '        <tr>' +
                  '        <th>' +
                  'Channel' +
                  '            ' +
                  '        </th>' +
                  '         <th>' +
                  '<span class="dot blue"></span> Audience Size' +
                  '            ' +
                  '        </th>' +
                  '         <th>' +
                  '<span class="dot pink"></span> Suggested Spend' +
                  '            ' +
                  '        </th>' +
                  '        </tr>' +
                  '    </thead>' +
                  '    <tbody>' +
                  tableRows +
                  '<tr class=\'last-table-row\'>' +
                  '    <td>' +
                  '<b>ALL CHANNELS</b>' +
                  '        ' +
                  '    </td>' +
                  '    <td>' +
                   numeral(options.series[0][options.dataPointIndex].toFixed(2)).format(getNumberFormats().number)
                  +
                  '        ' +
                  '    </td>' +
                  '    <td>' +
                  numeral(options.series[1][options.dataPointIndex]).format(getNumberFormats().currencyRound) +
                  '        ' +
                  '    </td>' +
                  '</tr>' +
                  '    </tbody>' +
                  '</table>' +
                  '</div>'
                );
              },
            },
          },
        };
        this.series[0].data = this.metrics.audienceSize.map((item, index) => {
          return {
            x: this.metrics.dates[index],
            y: item ? item : 0,
          };
        });
        this.series[1].data = this.metrics.suggestedMonthlySpend.map(
          (item, index) => {
            return {
              x: this.metrics.dates[index],
              y: item ? item : 0,
            };
          }
        );
        this.series[0].description = this.channelIndividuals;
        this.series[1].description = this.channelIndividuals;
        this.chartDataIsLoaded = true;
      },
    },
  },
};
</script>
<style>
.modal-header {
  width: 100%;
  background-color: #eceff1;
  padding: 15px;
}
.modal-container {
  padding: 10px;
  font-size: 10px;
}

.modal-container th {
  font-weight: 400;
  padding: 5px;
}

.modal-container td {
  padding: 5px;
}

.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}
.pink {
  background-color: #e670c0;
}
.blue {
  background-color: #00a2ea;
}

table {
  text-align: center;
}

.last-table-row {
  padding: 15px;
}
</style>
