export const getMarketAnalyzerBaseUrl = () => {
    switch (process.env.VUE_APP_APP_ENV) {
        case 'production':
            return process.env.VUE_APP_MARKET_ANALYZER_PROD_URL;
        case 'stage':
            return process.env.VUE_APP_MARKET_ANALYZER_STAGING_DEV_URL;
        case 'local':
            return process.env.VUE_APP_MARKET_ANALYZER_LOCAL_DEV_URL;
        default:
            return process.env.VUE_APP_MARKET_ANALYZER_PROD_URL;
    }
}

export const getMapsUrl = (place_id) => {
    return `https://www.google.com/maps/place/?q=place_id:${place_id}`
}


export const channelFilterAllChannels = 'all channels';

