<template>
    <div>
        <div
            v-if="!showFileInput"
            class="button-action active button-width"
            @click="handleFileInput">
            UPLOAD {{ uploadType === "favicon" ? "FAVICON" : "LOGO" }}
        </div>

        <div
            v-if="showFileInput"
            class="row">
            <div
                v-if="uploadType === 'favicon'"
                class="column image-preview">
                <img
                    :width="30"
                    :src="url">
            </div>
            <div
                v-else
                class="column image-preview">
                <div
                    :style="{ backgroundImage: `url(${url})` }"
                    class="logo-bounding-box" />
            </div>
            <div
                class="column"
                style="flex: 3 0px">
                <v-text-field
                    v-model="fileName"
                    label="SELECT YOUR IMAGE"
                    class="styled-field"
                    readonly />

                <input
                    ref="fileInput"
                    type="file"
                    style="display: none; visibility: hidden"
                    :accept="
                        uploadType === 'favicon' ? faviconValidation : logoValidation
                    "
                    @change="onFilePicked">
            </div>
            <div class="column-button">
                <div
                    class="button-action active"
                    @click="onPickFile">
                    UPLOAD
                </div>
            </div>
            <div class="column-button">
                <div
                    class="button-action remove"
                    @click="removeSelectedFile">
                    REMOVE
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    savedLogo: String,
    uploadType: String,
  },
  data() {
    return {
      showFileInput: false,
      fileName: '',
      fileObject: null,
      url: '',
      logoValidation: 'image/*,.jpg, .png, jpeg',
      faviconValidation: 'image/*,.jpg, .png, jpeg',
    };
  },
  watch: {
    fileObject() {
      if (this.uploadType === 'favicon') {
        this.$emit('faviconListener', this.fileObject);
      }
      this.$emit('logoListener', this.fileObject);
    },
  },
  mounted() {
    this.setLogoDataIfExist();
  },
  methods: {
    setLogoDataIfExist() {
      if (this.savedLogo) {
        this.url = this.savedLogo;
        this.fileName =
          this.uploadType === 'favicon' ? 'SELECTED FAVICON' : 'SELECTED LOGO';
        this.showFileInput = true;
      }
    },
    handleFileInput() {
      this.showFileInput = !this.showFileInput;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        // Check validity of file
        if (this.fileName.lastIndexOf('.') <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.url = fr.result;
          this.fileObject = files[0];
        });
      } else {
        this.clearFileData();
      }
    },
    removeSelectedFile() {
      this.clearFileData();
    },
    clearFileData() {
      this.$emit('fileRemovedEventListener', this.uploadType);
      this.fileName = '';
      this.fileObject = null;
      this.url = '';
    },
  },
};
</script>

<style>
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 1 100%;
  align-items: center;
}

.column-button {
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.button-action {
  border: solid;
  border-radius: 5px;
  padding: 4px;
  text-align: center;
}
.remove {
  border-color: #ddd;
  background-color: #ddd;
  color: #fff;
  cursor: pointer;
}
.button-width {
  width: 40%;
}
.image-preview {
  margin-right: 15px;
}
.active {
  border-color: #51c1f7;
  color: #51c1f7;
  cursor: pointer;
}
.logo-bounding-box {
  width: 80px;
  height: 55px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>