<template>
    <div>
        <h4>{{ title }}</h4>
        <table cellspacing="0">
            <tr>
                <td
                    v-for="(scheme, index) in schemes"
                    :key="index"
                    class="cell"
                    :class="[index === 0 ? 'firstCell' : '', index === schemes.length -1 ? 'lastCell' : '']"
                    :style="{ backgroundColor: scheme }" />
            </tr>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ColorPalette',
  props: {
    schemes: Array,
    title: String
  }
};
</script>
<style scoped>
table {
    margin-top: 5px;
}
.cell {
  padding: 15px;
}
.lastCell {
    border-radius: 0px 6px 6px 0px;
}
.firstCell {
    border-radius: 6px 0px 0px 6px;
}
</style>