<template>
    <v-menu
        v-model="showMenu"
        absolute
        offset-y
        style="max-width: 600px"
        :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <div
                class="edit-button"
                v-bind="attrs"
                v-on="on">
                EDIT
            </div>
        </template>

        <v-card>
            <v-card-text>
                <ColorPicker
                    :value="colorSpect"
                    @input="onColorChange" />
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { Sketch } from 'vue-color';
export default {
  components: {
    ColorPicker: Sketch,
  },
  props: {
     emitterKey: String,
  },
  data() {
    return {
      showMenu: false,
      selectedColor: '',
      colorSpect: {
        hex: '#000000',
      },
    };
  },
  watch: {
      selectedColor() {
      this.$emit(this.emitterKey, this.selectedColor);
    }
  },
  methods: {
    onColorChange(newColor) {
      this.selectedColor = newColor.hex;
    },
  }
};
</script>

<style>
.edit-button {
  border: solid;
  border-radius: 5px;
  padding: 4px;
  text-align: center;
  border-color: #51c1f7;
  color: #51c1f7;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}
</style>