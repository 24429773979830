<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        label="Report"
        :width="750"
        @input="$emit('input', $event)">
        <template>
            <div class="alerts current">
                <p class="current-account">
                    Send Report to Recipients
                </p>
                <p class="current-account">
                    Select Dates
                </p>
                <p>
                    <date-range-picker-presets :panel-position="'right'" />
                </p>

                <p class="current-account">
                    Recipients Selection
                </p>
                <notification-emails v-model="autoDetectNotificationEmails" />
                <styled-button
                    class="send-email"
                    :disabled="loading"
                    :loading="loading"
                    @click="sendEmail">
                    Send Report
                </styled-button>
                <v-alert
                    v-model="alert"
                    :type="alertType"
                    transition="fade-transition"
                    class="mt-4"
                    dismissible>
                    {{ alertMessage }}
                </v-alert>
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import DateRangePickerPresets from '@/components/globals/DateRangePickerPresets';
import NotificationEmails from '@/components/globals/NotificationEmails';
import StyledButton from '@/components/globals/StyledButton';
import moment from 'moment';

export default {
  name: 'ReportSlideout',
  components: {
    StyledSlideout,
    DateRangePickerPresets,
    NotificationEmails,
    StyledButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      autoDetectNotificationEmails: [],
      alert: false,
      alertType: '',
      alertMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'userIsClient',
      'activeOnboardings',
      'activeDealerOnboardings',
      'activeOnboardingsCount',
    ]),
    ...mapState({
      currentDealer: (state) => state.dealer.currentDealer,
      dateRange: (state) => state.metrics.dateRange,
    }),
  },
  created() {},
  methods: {
    showAllAlerts() {
      this.$emit('input', false);
      this.$router.push({
        name: 'communication-central',
        params: {
          agency_id: this.currentAgency.id,
        },
      });
    },
    async sendEmail() {
      const config = {
        method: 'POST',
        url: 'market_analyzer_results/export',
        data: {
          notification_emails: [],
          start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
          end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
          business_type : this.filters
        },
      };

      this.autoDetectNotificationEmails.forEach((element) => {
        config.data.notification_emails.push({
          name: element.email.substring(0, element.email.lastIndexOf('@')),
          email: element.email,
        });
      });

      this.loading = true;

      try {
        await this.$http.axios.request(config);
        this.loading = false;
        this.alertType = 'success';
        this.alertMessage = 'Email sent!';
        this.alert = true;
      } catch (error) {
        this.loading = false;
        this.alertType = 'error';
        this.alertMessage = 'Email not sent!';
        this.alert = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.onboardings,
.alerts {
  padding: 20px 30px 35px;
  &.all {
    background: $gray-lightshade;
  }
  .status {
    &.critical {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      padding: 2px 10px;
      text-transform: uppercase;
      color: $white;
      background-color: $carnation;
      border-radius: 5px;
    }
  }
}
.send-email {
  display: block;
  margin-top: 30px;
}
.tab-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 10px;
  text-transform: uppercase;
  color: $white;
  background-color: #00a2ea;
  border-radius: 5px;
}
.current-account {
  color: $blue-primary;
  font-size: 20px;
  font-weight: 600;
}
</style>