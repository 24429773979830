<template>
    <v-menu
        v-model="showPicker"
        :close-on-content-click="false"
        absolute
        offset-y
        style="max-width: 600px">
        <template #activator="{ on, attrs }">
            <v-text-field
                v-model="componentColor"
                :label="title"
                class="mb-3 styled-field"
                v-bind="attrs"
                readonly
                v-on="on">
                <template #prepend-inner>
                    <span
                        :style="{ backgroundColor: componentColor }"
                        class="square" />
                </template>
            </v-text-field>
        </template>
        <v-card>
            <v-card-text>
                <ColorPicker
                    :value="colorSpect"
                    @input="onColorChange" />
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  name: 'ColorPickerInput',
  components: {
    ColorPicker: Sketch,
  },
  props: {
    color: String,
    emitterKey: String,
    title: String,
  },
  data: () => ({
    colorSpect: {
      hex: '#000000',
    },
    componentColor: '',
    showPicker: false,
  }),
  watch: {
    componentColor() {
      this.$emit(this.emitterKey, this.componentColor);
    },
  },
  mounted() {
    this.componentColor = this.color;
  },
  methods: {
    onColorChange(newColor) {
      this.componentColor = newColor.hex;
    },
  },
};
</script>

<style>
.square {
  padding: 10px;
  margin-left: 5px;
  margin-top: 1px;
  border-radius: 3px;
}
</style>