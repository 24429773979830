
<template>
    <styled-slideout
        class="alert-slideout"
        :value="value"
        :width="900"
        @input="$emit('input', $event)">
        <template>
            <div class="slide-out-container">
                <div class="title-container">
                    <div class="current-account">
                        Market Report for {{ stringSplit(data.name) }}
                    </div>
                    <div class="action-icons-container">
                        <div class="external-link">
                            <img
                                class="clickable-icon"
                                :src="require('@/assets/icons/external-link.svg')"
                                width="18px"
                                alt="External link"
                                @click="openNewSession(data.uuid, data.stage, data.server_data)">
                            <p>Market Analyzer</p>
                        </div>
                        <div
                            v-if="data.user"
                            class="external-link user-link">
                            <div class="tooltip__layout">
                                <img
                                    class="clickable-icon"
                                    :src="require('@/assets/icons/user-circle.svg')"
                                    width="18px"
                                    alt="External link"
                                    @mouseenter="showTooltip = true"
                                    @mouseleave="showTooltip = false">
                                <p>Ran By</p>
                                <span
                                    class="tooltip__content"
                                    :class="{ show: showTooltip }">
                                    Email: {{ data.user.email }} <br>
                                    Name: {{ data.user.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-out-content">
                    <div class="content-subtitle">
                        Business Info
                    </div>
                    <div class="business-info-container">
                        <div class="business-info-item">
                            Business: <span class="link">{{ stringSplit(data.name) }}</span>
                        </div>
                        <div class="business-info-item">
                            Website:
                            <span
                                class="link"
                                @click="goToWebsite(data.website)">{{
                                    data.website | urlFormatter | truncate(25, "...")
                                }}</span>
                        </div>
                        <div class="business-info-item">
                            Location: <span
                                class="link"
                                @click="goToMaps">Map</span>
                        </div>
                    </div>
                    <div class="business-info-table-container">
                        <table>
                            <thead>
                                <th>Total Audience Size</th>
                                <th>Suggested Monthly Spend</th>
                                <th>Session Time</th>
                                <th>Date</th>
                                <th>Report Ran By</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{
                                            data.audience_size
                                                | numFormat(numberFormatsAbbreviated.number)
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            data.suggested_monthly_spend
                                                | numFormat(numberFormats.currencyRound)
                                        }}
                                    </td>
                                    <td>
                                        {{ data.session_average | numFormat(numberFormats.time) }}
                                    </td>
                                    <td>
                                        {{
                                            data.created_at
                                                | localizeTimezone
                                                | moment("MM/DD/YY @ h:mma")
                                        }}
                                    </td>
                                    <td>{{ stringSplit(data.name) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="content-subtitle">
                    Enabled
                </div>
                <div
                    v-if="data.channel_metrics"
                    class="channels-table-container">
                    <table class="custom-table">
                        <thead>
                            <th>Channel</th>
                            <th>Market Size</th>
                            <th>Targeted Audience Size</th>
                            <th>
                                <div class="bordered-bottom">
                                    CPM
                                </div>
                                <div>Freq</div>
                            </th>
                            <th>Suggested Monthly Spend</th>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(metric, index) in enabledMetrics"
                                :key="index"
                                class="custom-row">
                                <td>
                                    <img
                                        :src="
                                            require(`@/assets/icons/${stringToLowerCase(
                                                metric.channel
                                            )}.svg`)
                                        "
                                        :alt="`${stringToLowerCase(metric.channel)}-icon`">
                                    <span> {{ metric.channel }} </span>
                                </td>
                                <td class="center-aligned">
                                    {{
                                        metric.customization.geographicArea.radius
                                            ? `${metric.customization.geographicArea.radius}mi`
                                            : ""
                                    }}
                                </td>
                                <td class="center-aligned">
                                    {{
                                        Math.round(metric.customization.audienceTargetSize)
                                            | numFormat(numberFormatsAbbreviated.number)
                                    }}
                                    {{
                                        (metric.customization.audienceTargetPercentage / 100)
                                            | numFormat(numberFormats.percent)
                                    }}
                                </td>
                                <td class="center-aligned">
                                    <div class="bordered-bottom">
                                        {{
                                            metric.customization.cpm
                                                | numFormat(numberFormats.currencyRound)
                                        }}
                                    </div>
                                    <div>{{ metric.customization.frequency }}</div>
                                </td>
                                <td class="center-aligned">
                                    {{
                                        metric.suggestedMonthlySpend
                                            | numFormat(numberFormats.currencyRound)
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-else
                    class="no-data">
                    No Data
                </div>

                <div class="content-subtitle">
                    Potential Results - *Automotive Vertical Averages
                </div>

                <div
                    v-if="data.channel_metrics"
                    class="potential-results-table-container">
                    <table class="custom-table">
                        <thead>
                            <th style="width: 10%">
                                <div class="bordered-bottom">
                                    Impressions
                                </div>
                                <div>CPM</div>
                            </th>
                            <th style="width: 20%">
                                CTR
                            </th>
                            <th style="width: 10%">
                                <div class="bordered-bottom">
                                    Clicks
                                </div>
                                <div>CPC</div>
                            </th>
                            <th style="width: 20%">
                                Conversion Rate
                            </th>
                            <th style="width: 10%">
                                <div class="bordered-bottom">
                                    Leads
                                </div>
                                <div>CPL</div>
                            </th>
                            <th>Close Rate</th>
                            <th style="width: 10%">
                                <div class="bordered-bottom">
                                    Sales
                                </div>
                                <div>CPS</div>
                            </th>
                        </thead>
                        <tbody>
                            <tr class="custom-row">
                                <td>
                                    <div class="bordered-bottom">
                                        {{
                                            metricsAverage.impressions
                                                ? Math.round(metricsAverage.impressions)
                                                : 0 | numFormat(numberFormatsAbbreviated.number)
                                        }}
                                    </div>
                                    <div>{{ Math.round(metricsAverage.cpm) }}</div>
                                </td>
                                <td class="center-aligned">
                                    <div>
                                        {{ metricsAverage.ctr | numFormat(numberFormats.number) }}
                                    </div>
                                </td>
                                <td>
                                    <div class="bordered-bottom">
                                        {{
                                            Math.round(metricsTotals.clicks)
                                                | numFormat(numberFormatsAbbreviated.number)
                                        }}
                                    </div>
                                    <div>
                                        {{
                                            Math.round(cpc) | numFormat(numberFormats.currencyRound)
                                        }}
                                    </div>
                                </td>
                                <td class="center-aligned">
                                    {{
                                        (metricsAverage.conversionRate / 100)
                                            | numFormat(numberFormats.percent)
                                    }}
                                </td>
                                <td>
                                    <div class="bordered-bottom">
                                        {{ Math.round(metricsTotals.leads) }}
                                    </div>
                                    <div>
                                        {{
                                            Math.round(cpl) | numFormat(numberFormats.currencyRound)
                                        }}
                                    </div>
                                </td>
                                <td class="center-aligned">
                                    {{
                                        (metricsAverage.closeRate / 100)
                                            | numFormat(numberFormats.percent)
                                    }}
                                </td>
                                <td>
                                    <div class="bordered-bottom">
                                        {{ Math.round(metricsTotals.sales) }}
                                    </div>
                                    <div>
                                        {{
                                            Math.round(cps) | numFormat(numberFormats.currencyRound)
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-else
                    class="no-data">
                    No Data
                </div>

                <div class="content-subtitle">
                    Inventory
                </div>

                <div
                    v-if="inventory && inventory.body_types.length"
                    class="inventory-container">
                    <div>
                        Vehicles:
                        <ul>
                            <li>
                                Used:
                                <span class="inventory-row-value">{{
                                    inventoryStats.used | numFormat(numberFormats.percent)
                                }}</span>
                            </li>
                            <li>
                                New:
                                <span class="inventory-row-value">{{
                                    inventoryStats.new | numFormat(numberFormats.percent)
                                }}</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        Body Styles:
                        <table>
                            <tr>
                                <td
                                    v-for="(body_type, index) in inventory.body_types.slice(0, 2)"
                                    :key="index">
                                    <span class="inventory-row-label">
                                        {{ body_type.label }}:
                                    </span>
                                    <span class="inventory-row-value">{{ body_type.value }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    v-for="(body_type, index) in inventory.body_types.slice(2, 4)"
                                    :key="`${index}`">
                                    <span class="inventory-row-label">
                                        {{ body_type.label }}:
                                    </span>
                                    <span class="inventory-row-value">
                                        {{ body_type.value }}
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        Models:
                        <table>
                            <tr>
                                <td
                                    v-for="(model, index) in inventory.models.slice(0, 2)"
                                    :key="index">
                                    <span class="inventory-row-label"> {{ model.label }}: </span>
                                    <span class="inventory-row-value">{{ model.value }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    v-for="(model, index) in inventory.models.slice(2, 4)"
                                    :key="`${index}`">
                                    <span class="inventory-row-label"> {{ model.label }}: </span>
                                    <span class="inventory-row-value"> {{ model.value }} </span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div
                    v-else
                    class="no-data">
                    No Data
                </div>

                <div class="content-subtitle">
                    Market Competitors
                </div>

                <div
                    v-if="competitors"
                    class="competitors-container">
                    <div class="row">
                        <div
                            v-for="(competitor, index) in competitors"
                            :key="index"
                            class="column">
                            <img
                                :src="competitor.image || competitor.photo_url"
                                class="competitor-image"
                                :alt="competitor.name">
                            <p>{{ competitor.name }}</p>
                            <p>
                                {{
                                    `${
                                        competitor.address.street || competitor.address.streetNumber
                                    }
                 ${competitor.address.route ? competitor.address.route : ""}
                 ${competitor.address.city},
                  ${
                                        competitor.address.administrative_area ||
                                        competitor.address.state
                                    } ${
                                        competitor.address.postal_code ||
                                        competitor.address.postalCode
                                    }`
                                }}
                            </p>
                            <p class="link">
                                {{ competitor.website | urlFormatter | truncate(20, "...") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="no-data">
                    No Data
                </div>
                <div class="content-subtitle">
                    Session Platform
                </div>
                <platform-overview :data="data.server_data" />
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { sumBy } from 'lodash';
import ApiRepository from '../../api-repository';
import PlatformOverview from './PlatformOverview';
import getNumberFormats from '@/helpers/numberFormats';

export default {
  name: 'SessionReportSlideout',
  components: {
    StyledSlideout,
    PlatformOverview,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      enabledChannelMetrics: [],
      loadedCompetitors: null,
      loadedInventory: null,
      numberFormats: getNumberFormats(),
      numberFormatsAbbreviated: getNumberFormats(true),
    };
  },
  computed: {
    ...mapGetters([
      'userIsClient',
      'activeOnboardings',
      'activeDealerOnboardings',
      'activeOnboardingsCount',
    ]),
    ...mapState({
      currentDealer: (state) => state.dealer.currentDealer,
    }),
    enabledMetrics() {
      if (this.data.channel_metrics) {
        return this.data.channel_metrics.filter((item) => item.enabled);
      }
      return [];
    },
    competitors() {
      if (this.data.competitors) {
        return this.data.competitors;
      }
      return this.loadedCompetitors;
    },
    inventory() {
      if (this.data.inventory) {
        return this.data.inventory;
      }
      return this.loadedInventory;
    },
    globalSpend() {
      return sumBy(this.enabledMetrics, function(item) {
        return (
          (parseInt(item.metrics.impressions) / 1000) * item.customization.cpm
        );
      });
    },
    cpc() {
      if (this.metricsTotals.clicks > 0) {
        return this.globalSpend / this.metricsTotals.clicks;
      }
      return 0;
    },
    cpl() {
      if (this.metricsTotals.leads > 0) {
        return this.globalSpend / this.metricsTotals.leads;
      }
      return 0;
    },
    cps() {
      if (this.metricsTotals.sales > 0) {
        return this.globalSpend / this.metricsTotals.sales;
      }
      return 0;
    },
    metricsAverage() {
      const length = this.enabledMetrics.length;
      return {
        impressions:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.impressions);
              }) / length
            : 0,
        cpm:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.customization.cpm);
              }) / length
            : 0,
        ctr:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.ctr);
              }) / length
            : 0,
        clicks:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.clicks);
              }) / length
            : 0,
        conversionRate:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.conversionRate);
              }) / length
            : 0,
        leads:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.leads);
              }) / length
            : 0,
        closeRate:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.closeRate);
              }) / length
            : 0,
        sales:
          length > 0
            ? sumBy(this.enabledMetrics, function(item) {
                return parseInt(item.metrics.sales);
              }) / length
            : 0,
      };
    },
    metricsTotals() {
      return {
        clicks: sumBy(this.enabledMetrics, function(item) {
          return parseInt(item.metrics.clicks);
        }),
        leads: sumBy(this.enabledMetrics, function(item) {
          return parseInt(item.metrics.leads);
        }),
        sales: sumBy(this.enabledMetrics, function(item) {
          return parseInt(item.metrics.sales);
        }),
      };
    },
    inventoryStats() {
      return {
        new:
          this.inventory.stats.total > 0
            ? this.inventory.stats.new / this.inventory.stats.total
            : 0,
        used:
          this.inventory.stats.total > 0
            ? this.inventory.stats.used / this.inventory.stats.total
            : 0,
      };
    },
  },
  watch: {
    data() {
      if (!this.inventory) {
        this.loadFromApi('inventory');
      }
      if (!this.competitors) {
        this.loadFromApi('competitors');
      }
    },
  },
  methods: {
    async loadFromApi(path) {
      const apiRepository = new ApiRepository();
      const response = await apiRepository.getDataFromSessionsReport(
        this.data,
        path
      );
      if (path === 'inventory') {
        this.setInventory(response);
      } else {
        this.setCompetitors(response);
      }
    },
    setInventory(item) {
      console.log(item);
      this.loadedInventory = item;
    },
    setCompetitors(item) {
      console.log(item);
      this.loadedCompetitors = item;
    },
    showAllAlerts() {
      this.$emit('input', false);
    },
    goToWebsite() {
      this.$emit('goToWebsiteEvent', this.data.website);
    },
    goToMaps() {
      this.$emit('goToMapsEvent', this.data);
    },
    stringSplit(value) {
      if (value) {
        return value.split(',')[0];
      }
    },
    stringToLowerCase(value) {
      return value.toLowerCase();
    },
    openNewSession(sessionId, stage, serverData) {
      const path =  serverData.HTTP_ORIGIN === 'https://marketanalyzer.io' ? '' : 'analyzer/';
      window.open(
        `${serverData.HTTP_ORIGIN}/${path}#/${stage}/?session_id=${sessionId}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.action-icons-container {
  display: flex;
  justify-content: space-between;
}
.onboardings,
.slide-out-container {
  padding: 20px 30px 35px;

  .title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .external-link {
    cursor: pointer;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
  }

  .user-link {
    padding-left: 20px;
  }

  .content-subtitle {
    font-size: 15px;
    color: #6d797f;
    margin-bottom: 35px;
    margin-top: 40px;
  }

  .business-info-container {
    display: flex;
    .business-info-item {
      font-size: 12px;
      padding: 0px 30px;
    }
    .business-info-item span {
      margin-left: 10px;
    }
  }

  .link {
    color: $blue-primary;
    text-decoration: underline;
    cursor: pointer;
  }
  .business-info-table-container {
    table {
      padding: 25px 0px;
    }
    th {
      padding: 0px 20px;
      font-size: 12px;
      font-weight: 400;
      color: #6d797f;
    }
    td {
      padding: 10px 20px;
      font-size: 13px;
      color: #3f4649;
    }
  }

  .current-account {
    color: $blue-primary;
    font-size: 17px;
    font-weight: 400;
  }

  .competitor-image {
    width: 100%;
    height: 10vw;
    object-fit: cover;
    border-radius: 20px;
  }

  .column {
    float: left;
    width: 20%;
    padding: 10px;
    margin-right: 35px;

    p {
      font-size: 12px;
    }
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .channels-table-container {
    padding-left: 30px;
  }

  .potential-results-table-container {
    padding-left: 30px;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
    th {
      vertical-align: top;
      color: #6d797f;
      font-weight: 400;
      font-size: 12px;
    }
    tbody {
      background-color: rgba(143, 158, 166, 0.05);
    }
    .custom-row {
      border-bottom: solid 2px rgba(143, 158, 166, 0.5);
      border-top: solid 2px rgba(143, 158, 166, 0.5);
      .center-aligned {
        text-align: center;
      }
      td {
        text-align: left;
        padding: 15px;
        span {
          margin-left: 10px;
        }
      }
    }
  }

  .bordered-bottom {
    border-bottom: solid 1px #6d797f;
  }

  .inventory-container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    color: #6d797f;

    ul {
      list-style: none;
      margin-top: 20px;
    }
    table {
      margin-top: 20px;
      text-align: right;
    }
    .inventory-row-value {
      margin-left: 20px;
      margin-right: 20px;
      color: #020202;
    }
  }
  .no-data {
    font-weight: bold;
  }
}

.tooltip__layout {
  position: relative;
  display: inline-block;
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  display: inline-block;
}

.tooltip__content {
  text-align: left;
  text-transform: lowercase;
  padding-left: 50px;
  right: 40px;
  top: -5px;
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  padding: 5px 20px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 230px;
  display: none;
}
</style>
