
<template>
    <div>
        <div class="main-toolbar">
            <div class="date-picker-container">
                <date-range-picker-presets
                    class="md6 xs12 mr-5"
                    :panel-position="'right'" />
            </div>
            <action-button
                icon="cog"
                @click="whiteLabelSlideoutOpen = true">
                SETTINGS
            </action-button>
        </div>

        <styled-card>
            <template #heading>
                Total Overview
            </template>
            <template #action-buttons>
                <action-button
                    icon="refresh"
                    @click="getMetricsData">
                    Refresh
                </action-button>
            </template>
            <div
                v-if="loading"
                class="custom-row loader-wrapper">
                <loader label="Loading Metrics" />
            </div>
            <div :class="[loading ? 'hidden' : '', 'custom-row']">
                <div class="custom-column left-bordered">
                    <div
                        style="
              display: flex;
              justify-content: space-between;
              padding: 0px 30px;
            ">
                        <div>
                            <v-select
                                v-model="selectedChannel"
                                label="Channels"
                                class="styled-field"
                                :items="channelsFilter"
                                @change="getMetricsData()" />
                        </div>
                        <div style="font-size: 12px">
                            <span class="dot blue-background" /> Audience size
                            <span class="dot pink-background" /> Suggested spend
                        </div>
                    </div>
                    <market-analyzer-sessions-charts :metrics="metrics" />
                </div>
                <div class="right-column">
                    <market-analyzer-channels-overview
                        :metrics="metrics"
                        :channel="selectedChannel" />
                </div>
            </div>
        </styled-card>
        <styled-card class="card">
            <template #heading>
                Latest business searches
            </template>
            <template #action-buttons>
                <action-button
                    icon="add-permission"
                    @click="reportSlideoutOpen = true">
                    EXPORT
                </action-button>
                <action-button
                    icon="refresh"
                    @click="getMetricsData">
                    Refresh
                </action-button>
            </template>
            <div class="session-table-container">
                <div class="layout align-center table-actions">
                    <div>
                        <v-text-field
                            v-model="searchTerm"
                            append-icon="search"
                            label="Search Business"
                            single-line
                            hide-details
                            class="styled-field"
                            @keyup="searchByBusinessName" />
                    </div>
                    <div
                        v-if="userIsAdmin"
                        class="admin-only pa-2 ml-4">
                        <v-checkbox
                            v-model="showAllAgencies"
                            label="All agencies"
                            color="primary"
                            class="styled-checkbox"
                            @change="allAgencies" />
                    </div>
                </div>
                <sessions-table
                    emitter-key="sortTableListener"
                    :sessions="sessions"
                    :pagination="pagination"
                    :loading="loading"
                    @sortTableListener="sortTable"
                    @clicked-change-page="paginateTable"
                    @changed-amount-of-items="changeItemsPerPage" />
            </div>
        </styled-card>
        <report-slideout
            v-model="reportSlideoutOpen"
            :value="true" />
        <white-label-dialog
            v-model="whiteLabelSlideoutOpen"
            :value="true" />
    </div>
</template>
<script>
import SessionsTable from './SessionsTable';
import MarketAnalyzerSessionsCharts from './market-analyzer/MarketAnalyzerSessionsCharts';
import MarketAnalyzerChannelsOverview from './market-analyzer/MarketAnalyzerChannelsOverview';
import ActionButton from '../globals/ActionButton';
import DateRangePickerPresets from '@/components/globals/DateRangePickerPresets';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Loader from '../globals/Loader.vue';
import ReportSlideout from './ReportSlideout';
import WhiteLabelDialog from '../white-label/WhiteLabelDialog';
import { debounce } from 'lodash';
import StyledCard from '../globals/StyledCard';

export default {
  components: {
    DateRangePickerPresets,
    MarketAnalyzerSessionsCharts,
    MarketAnalyzerChannelsOverview,
    SessionsTable,
    StyledCard,
    ActionButton,
    Loader,
    ReportSlideout,
    WhiteLabelDialog,
  },
  title: 'Sessions Reports Access Control',
  data() {
    return {
      showAllAgencies: false,
      filters: [],
      pagination: {
        currentPage: 1,
        limit: 100,
        totalItems: null,
        from: 0,
        to: 0,
        totalPages: 0,
      },
      searchTerm: '',
      loading: true,
      metrics: null,
      reportSlideoutOpen: false,
      whiteLabelSlideoutOpen: false,
      sort: 'created_at:desc',
      channelsFilter: [
        'All Channels',
        'Facebook',
        'Snapchat',
        'TikTok',
        'Pinterest',
      ],
      selectedChannel: 'All Channels',
      sessions: [],
    };
  },
  computed: {
    ...mapState({
      dateRange: (state) => state.metrics.dateRange,
      currentAgency: (state) => state.agency.currentAgency,
    }),
    ...mapGetters(['userIsAdmin']),
    dateRangeFormatted() {
      if (moment(this.dateRange.startDate).isSame(this.dateRange.endDate)) {
        return {
          startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        };
      }
      return {
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
      };
    },
  },
  watch: {
    dateRangeFormatted() {
      this.getMetricsData();
    },
  },
  created() {
    this.getMetricsData();
  },
  methods: {
    allAgencies: debounce(function() {
      this.getMetricsData();
    }, 400),
    async getMetricsData() {
      try {
        this.loading = true;
        const response = await this.$http.get('sessions/reports/search', {
          channel: this.selectedChannel.toLowerCase(),
          from: `${this.dateRangeFormatted.startDate}`,
          to: `${this.dateRangeFormatted.endDate}`,
          sort: this.sort,
          searchTerm: this.searchTerm,
          agency: this.currentAgency.id,
          page: this.pagination.currentPage ? this.pagination.currentPage : 1,
          limit: this.pagination.limit,
          showAll: Number(this.showAllAgencies),
        });

        this.metrics = response.data.aggregations;
        this.sessions = response.data.sessionData.data;
        this.pagination.totalItems = response.data.sessionData.pagination.total;
        this.pagination.currentPage =
          response.data.sessionData.pagination.current_page;
        this.pagination.from = response.data.sessionData.pagination.from;
        this.pagination.to = response.data.sessionData.pagination.to;
        this.pagination.totalPages =
          response.data.sessionData.pagination.total_pages;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error('Error retrieving sessions metrics', error);
      }
    },
    sortTable(value) {
      this.sort = value;
      this.getMetricsData();
    },
    paginateTable(value) {
      if (value === 'nextPage') {
        if (this.pagination.totalPages !== this.pagination.currentPage) {
          this.pagination.currentPage++;
          this.getMetricsData();
        }
      } else if (value === 'prevPage') {
        if (this.pagination.currentPage !== 1) {
          this.pagination.currentPage--;
          this.getMetricsData();
        }
      }
    },
    changeItemsPerPage(value) {
      if (value !== this.pagination.limit) {
        this.pagination.limit = value;
        this.pagination.currentPage = 1;
        this.getMetricsData();
      }
    },
    searchByBusinessName: debounce(function() {
      this.getMetricsData();
    }, 600),
  },
};
</script>
<style scoped>
.custom-column {
  width: 65%;
}

.right-column {
  width: 35%;
  padding: 15px;
}

.custom-row {
  display: flex;
  flex-direction: row;
}

.left-bordered {
  border-right: solid 1px #dee2e5;
  padding: 30px;
}

.card {
  margin-top: 80px;
}

.date-picker-container {
  padding: 20px 0px 20px 0px;
}
.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}
.pink-background {
  background-color: #e350b4;
}
.blue-background {
  background-color: #00a2ea;
}
.loader-wrapper {
  justify-content: center;
  padding: 100px 0px;
}
.hidden {
  display: none;
}
.table-actions {
  padding: 30px;
}
.main-toolbar {
  display: flex;
  justify-content: space-between;
}
</style>
