<template>
    <svg
        width="23px"
        height="22px"
        viewBox="0 0 28 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <rect
                id="path-g_l9nbb3nf-1"
                x="0"
                y="0"
                width="28"
                height="6.66666667" />
            <rect
                id="path-g_l9nbb3nf-3"
                x="0"
                y="0"
                width="28"
                height="6.66666667" />
            <rect
                id="path-g_l9nbb3nf-5"
                x="0"
                y="0"
                width="28"
                height="6.66666667" />
        </defs>
        <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <g
                id="lead-row"
                transform="translate(-1146.000000, -31.000000)">
                <g
                    id="Group-2"
                    transform="translate(1146.000000, 31.300000)">
                    <g
                        id="status-funnel-bottom"
                        transform="translate(0.000000, 14.033333)">
                        <mask
                            id="mask-g_l9nbb3nf-2"
                            fill="white">
                            <use xlink:href="#path-g_l9nbb3nf-1" />
                        </mask>
                        <g id="Mask" />
                        <path
                            id="Rectangle"
                            d="M3.79500532,-13.3333333 L22.8716613,-13.3333333 C23.1171212,-13.3333333 23.3161058,-13.1343488 23.3161058,-12.8888889 C23.3161058,-12.826703 23.3030559,-12.7652091 23.2777998,-12.7083829 L14.7839726,6.40272821 C14.7126387,6.56322955 14.5534736,6.66666667 14.3778342,6.66666667 L12.2888325,6.66666667 C12.1131931,6.66666667 11.9540279,6.56322955 11.882694,6.40272821 L3.38886686,-12.7083829 C3.28917615,-12.932687 3.39019526,-13.1953367 3.61449934,-13.2950274 C3.67132556,-13.3202835 3.73281939,-13.3333333 3.79500532,-13.3333333 Z"
                            :fill="colors.bottom"
                            mask="url(#mask-g_l9nbb3nf-2)" />
                    </g>
                    <g
                        id="status-funnel-middle"
                        transform="translate(0.000000, 7.366667)">
                        <mask
                            id="mask-g_l9nbb3nf-4"
                            fill="white">
                            <use xlink:href="#path-g_l9nbb3nf-3" />
                        </mask>
                        <g id="Mask" />
                        <polygon
                            id="Rectangle"
                            :fill="colors.middle"
                            mask="url(#mask-g_l9nbb3nf-4)"
                            points="3.11111111 -6.66666667 23.5555556 -6.66666667 14.6666667 13.3333333 12 13.3333333" />
                    </g>
                    <g
                        id="status-funnel-top"
                        transform="translate(0.000000, 0.700000)">
                        <mask
                            id="mask-g_l9nbb3nf-6"
                            fill="white">
                            <use xlink:href="#path-g_l9nbb3nf-5" />
                        </mask>
                        <g id="Mask" />
                        <path
                            id="Rectangle"
                            d="M3.79500532,0 L22.8716613,0 C23.1171212,1.78677774e-15 23.3161058,0.198984556 23.3161058,0.444444444 C23.3161058,0.506630375 23.3030559,0.568124208 23.2777998,0.624950429 L14.7839726,19.7360615 C14.7126387,19.8965629 14.5534736,20 14.3778342,20 L12.2888325,20 C12.1131931,20 11.9540279,19.8965629 11.882694,19.7360615 L3.38886686,0.624950429 C3.28917615,0.400646348 3.39019526,0.137996681 3.61449934,0.0383059784 C3.67132556,0.0130498801 3.73281939,2.88979126e-16 3.79500532,0 Z"
                            :fill="colors.top"
                            mask="url(#mask-g_l9nbb3nf-6)" />
                    </g>
                    <path
                        id="Combined-Shape"
                        d="M22.0167936,0 C22.9556777,0 23.7167936,0.761115925 23.7167936,1.7 C23.7167936,1.93786118 23.6668778,2.1730751 23.5702732,2.39043539 L23.5702732,2.39043539 L15.5702732,20.3904354 C15.2974209,21.004353 14.6886144,21.4 14.0167936,21.4 L14.0167936,21.4 L12.6498731,21.4 C11.9780523,21.4 11.3692457,21.004353 11.0963935,20.3904354 L11.0963935,20.3904354 L3.09639346,2.39043539 C2.71507652,1.53247228 3.10147459,0.527837305 3.9594377,0.146520367 C4.17679799,0.0499157913 4.41201191,0 4.64987309,0 L4.64987309,0 Z M17.3334488,14.699 L9.33244881,14.699 L11.7360615,20.1061385 C11.8787294,20.4271411 12.1775416,20.6466963 12.5197366,20.6915234 L12.6498731,20.7 L14.0167936,20.7 C14.4119823,20.7 14.7701038,20.4672665 14.9306051,20.1061385 L14.9306051,20.1061385 L17.3334488,14.699 Z M20.4444488,7.699 L6.22144881,7.699 L8.99944881,13.95 L9,13.7 L17.7774488,13.699 L20.4444488,7.699 Z M22.0167936,0.7 L4.64987309,0.7 C4.50995475,0.7 4.37159362,0.72936223 4.24373462,0.786188451 C3.73905044,1.01049253 3.51175746,1.60145428 3.73606154,2.10613847 L3.73606154,2.10613847 L5.99944881,7.2 L6,6.7 L20.8894488,6.699 L22.9306051,2.10613847 C22.9684893,2.02089913 22.9941672,1.93099219 23.0070765,1.83906798 L23.0167936,1.7 C23.0167936,1.14771525 22.5690783,0.7 22.0167936,0.7 L22.0167936,0.7 Z"
                        fill="#B9C3C7"
                        fill-rule="nonzero" />
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    props: {
        colors: {
            type: Object,
            required: true
        },
    }
};
</script>
