<template>
    <div class="table-container">
        <table
            cellspacing="0"
            cellpadding="0">
            <thead>
                <th
                    v-for="(header, index) in headers"
                    :key="index"
                    :style="{ width: header.width }"
                    :class="{ clickable: header.sortable }">
                    <span
                        v-if="header.value !== 'cpm-frequency'"
                        class="no-selectable"
                        @click="sort(header)">
                        {{ header.text }}
                    </span>
                    <span
                        v-else
                        class="no-selectable">
                        <div class="bordered-bottom">CPM</div>
                        <div>Frequency</div>
                    </span>
                    <span
                        v-if="header.sortable && header.clickCount"
                        class="sort-icon">
                        <img
                            :class="{ 'sort-arrow-rotated': header.clickCount === 2 }"
                            :src="require('@/assets/icons/arrow-sort.svg')"
                            alt="prev"
                            width="10px">
                    </span>
                </th>
            </thead>
            <tbody v-if="loading">
                <tr>
                    <td :colspan="headers.length + 1">
                        <loader label="Loading Sessions" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr
                    v-for="(item, index) in sessionsComputed"
                    :key="index">
                    <td>
                        <ul
                            style="
                list-style: none;
                margin-left: -10px;
                overflow-wrap: break-word;
              ">
                            <li>
                                Business: <span
                                    class="link"
                                    @click="goToWebsite(item.website)">{{ stringSplit(item.name) }}</span>
                            </li>
                            <li>
                                Website:
                                <span
                                    class="link"
                                    @click="goToWebsite(item.website)">{{
                                        item.website | urlFormatter | truncate(15, "...")
                                    }}</span>
                            </li>
                            <li>
                                Location: <span
                                    class="link"
                                    @click="goToMap(item)">Map</span>
                            </li>
                        </ul>
                    </td>
                    <td>
                        <span
                            v-for="(channel, i) in item.channel_metrics"
                            :key="i"
                            class="link"
                            @click="goToChannelDetails(channel.channel, item.uuid, item.server_data)">
                            {{
                                channel.enabled
                                    ? `${fbToMeta(channel.channel)} ${
                                        !isLastInArray(item.channel_metrics, i) ? "," : ""
                                    }`
                                    : ""
                            }}
                        </span>
                    </td>
                    <td>
                        {{
                            findAverageInCustomizationObject(
                                item.channel_metrics,
                                "audienceTargetSize"
                            ) | numFormat(numberFormatsAbbreviated.number)
                        }}
                    </td>
                    <td>
                        {{
                            findAverageInCustomizationObject(
                                item.channel_metrics,
                                "audienceTargetPercentage",
                                true
                            ) / 100 | numFormat(numberFormats.percent)
                        }}
                    </td>
                    <td>
                        {{ item.created_at | localizeTimezone | moment('MM/DD/YY @ h:mma') }}
                    </td>
                    <td>{{ item.session_average | numFormat(numberFormats.time) }}</td>
                    <td style="text-align: left">
                        <div class="bordered-bottom">
                            {{
                                findAverageInCustomizationObject(
                                    item.channel_metrics,
                                    "cpm",
                                    true
                                ) | numFormat(numberFormats.currencyRound)
                            }}
                        </div>
                        <div>
                            {{
                                findAverageInCustomizationObject(
                                    item.channel_metrics,
                                    "frequency",
                                    true
                                )
                                    ? findAverageInCustomizationObject(
                                        item.channel_metrics,
                                        "frequency",
                                        true
                                    )
                                    : "0.0"
                            }}
                        </div>
                    </td>
                    <td>
                        {{
                            findAverageInBaseObject(
                                item.channel_metrics,
                                "suggestedMonthlySpend"
                            ) | numFormat(numberFormats.currencyRound)
                        }}
                    </td>

                    <td class="center">
                        <img
                            class="clickable-icon"
                            :src="require('@/assets/icons/external-link.svg')"
                            width="15px"
                            @click="openNewSession(item.uuid, item.stage, item.server_data)">
                    </td>
                    <td style="padding-left: 15px">
                        <funnel-stage-svg :colors="getStageFunnelFills(item.stage)" />
                    </td>
                    <td style="padding-left: 15px">
                        <img
                            class="clickable-icon"
                            :src="require('@/assets/icons/list.svg')"
                            width="15px"
                            @click="setSelectedItem(item)">
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="!loading">
                <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td><span style="padding-top: 25px"> Rows per page </span></td>
                    <td>
                        <v-select
                            v-model="selectedRowsPerPage"
                            class="select"
                            :items="rowsPerPage"
                            @change="handleItemsLimit" />
                    </td>
                    <td />
                    <td colspan="2">
                        {{ `${pagination.from}-${pagination.to}` }} of
                        {{ pagination.totalItems.value }}
                    </td>
                    <td
                        colspan="2"
                        style="padding: 10px 40px">
                        <img
                            class="clickable-icon"
                            :src="require('@/assets/icons/chevron-left.svg')"
                            width="10px"
                            @click="handlePageChange('prevPage')">
                        <img
                            class="clickable-icon"
                            style="float: right"
                            :src="require('@/assets/icons/chevron-right.svg')"
                            width="10px"
                            @click="handlePageChange('nextPage')">
                    </td>
                </tr>
            </tfoot>
        </table>
        <session-report-slideout
            v-model="sessionReportSlideoutOpen"
            :value="true"
            :data="selectedItem"
            @goToMapsEvent="goToMapEventHandler()"
            @goToWebsiteEvent="gotToWebsiteEventHandler()" />
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import SessionReportSlideout from './SessionReportSlideout';
import Loader from '../globals/Loader.vue';
import getNumberFormats from '@/helpers/numberFormats';
import FunnelStageSvg from './market-analyzer/FunnelStageSvg';
import {
  getMapsUrl,
} from '@/helpers/marketAnalyzerHelpers';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
  components: {
    SessionReportSlideout,
    Loader,
    FunnelStageSvg
  },
  props: {
    sessions: Array,
    pagination: Object,
    loading: Boolean,
    emitterKey: String,
    emitterPaginationKey: String,
  },
  data() {
    return {
      sessionReportSlideoutOpen: false,
      selectedItem: {},
      numberFormats: getNumberFormats(),
      numberFormatsAbbreviated: getNumberFormats(true),
      headers: [
        {
          text: 'Business Info',
          value: 'business-info',
          width: '15%',
          sortable: true,
          clickCount: 0,
          realSortablefield: 'name',
        },
        {
          text: 'Channel(s)',
          value: 'channels',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Total Audience Size',
          value: 'total-audience-size',
          width: '5%',
          sortable: false,
        },
        {
          text: 'Total Audience Percent',
          value: 'total-audience-percent',
          width: '7%',
          sortable: false,
        },
        {
          text: 'Report Session',
          value: 'report-session',
          width: '11%',
          sortable: true,
          clickCount: 1,
          realSortablefield: 'created_at',
        },
        {
          text: 'Session time',
          value: 'average-time',
          width: '5%',
          sortable: false,
        },
        {
          text: 'CPM / Frequency',
          value: 'cpm-frequency',
          width: '7%',
          sortable: false,
        },
        {
          text: 'Suggested Monthly Spend',
          value: 'suggested-monthly-spend',
          width: '7%',
          sortable: false,
        },
        {
          text: 'MA Link',
          value: 'ma-link',
          width: '5%',
          sortable: false,
        },
        {
          text: 'Stage',
          value: 'stage',
          width: '6%',
          sortable: true,
          clickCount: 0,
          realSortablefield: 'stage',
        },
        {
          text: 'See Details',
          value: 'see-details',
          width: '6%',
          sortable: false,
        },
      ],
      sortEventEmitter: 0,
      paginationEventEmitter: 0,
      sortItems: [],
      rowsPerPage: [5, 10, 25, 50, 100],
      selectedRowsPerPage: 100,
      totalItems: 0,
      query: {
        channel: '',
        limit: 100,
        page: 1,
      },
      fbToMeta,
    };
  },
  computed: {
    ...mapState({
      gency: (state) => state.agency.currentAcurrentAgency,
    }),
    ...mapGetters(['userIsAdmin']),
    sessionsComputed() {
      return this.sessions;
    },
    paginationComputed() {
      return this.pagination;
    },
  },
  watch: {
    sortEventEmitter() {
      this.$emit(this.emitterKey, this.formatSortElements().join(','));
    },
  },

  methods: {
    sort(header) {
      if (header.sortable && !this.loading) {
        this.headers.find((item) => {
          if (item.value === header.value) {
            if (item.clickCount === 2) {
              item.clickCount = 0;
            } else {
              item.clickCount++;
            }
            this.handleSelectedItemsToSort(item);
          }
        });
      }
    },
    goToWebsite(url) {
      window.open(
              url
            );
    },
    goToMap(item) {
      this.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line
        let params = {
          address: item.addresss.street,
          location: {
            lat: parseFloat(item.coordinates.latitude),
            lng: parseFloat(item.coordinates.longitude),
          },
        };
        // eslint-disable-next-line
        this.geocoder = new google.maps.Geocoder();
        this.geocoder.geocode(params, function(results) {
          if (results.length > 0) {
            window.open(
              `${getMapsUrl(
                results[0].place_id
              )}`
            );
          }
        });
      });
    },
    goToMapEventHandler() {
        this.goToMap(this.selectedItem);
    },
    gotToWebsiteEventHandler() {
        this.goToWebsite(this.selectedItem.website);
    },
    goToChannelDetails(channel, sessionId, serverData) {
    const path =  serverData.HTTP_ORIGIN === 'https://marketanalyzer.io' ? '' : 'analyzer/';
      window.open(
        `${serverData.HTTP_ORIGIN}/${path}#/${channel}/?session_id=${sessionId}`
      );
    },
    handleSelectedItemsToSort(headerElement) {
      // Find in index the current sorting element
      let index = this.sortItems.findIndex(
        (item) => item.value === headerElement.value
      );

      if (index === -1) {
        // If not exist push it
        this.sortItems.push(headerElement);
      } else {
        if (headerElement.clickCount === 0) {
          // If  exist but clickcount equals 0
          this.sortItems.splice(index, 1);
        } else {
          this.sortItems[index] = headerElement;
        }
      }
      this.sortItems.map((item) => {
        item.sortType = item.clickCount === 1 ? 'asc' : 'desc';
      });
      this.sortEventEmitter++;
    },
    formatSortElements() {
      return this.sortItems.map((item) => {
        return `${item.realSortablefield}:${item.sortType}`;
      });
    },
    stringSplit(value) {
      return value.split(',')[0];
    },
    openNewSession(sessionId, stage, serverData) {
       const path =  serverData.HTTP_ORIGIN === 'https://marketanalyzer.io' ? '' : 'analyzer/';
      window.open(
        `${serverData.HTTP_ORIGIN}/${path}#/${stage}/?session_id=${sessionId}`
      );
    },
    getStageFunnelFills(stage) {
        return {
                top: '#7ED321',
                middle: stage === 'market-report' || stage === 'shared' ? '#F5A623' : 'transparent',
                bottom: stage === 'shared' ? '#EE5643' : 'trnasparent'
        };
         },
    setSelectedItem(item) {
      this.selectedItem = item;
      this.selectedItem.suggested_monthly_spend = this.findAverageInBaseObject(
        item.channel_metrics,
        'suggestedMonthlySpend'
      );
      this.selectedItem.audience_size = this.findAverageInCustomizationObject(
        item.channel_metrics,
        'audienceTargetSize'
      );
      this.sessionReportSlideoutOpen = true;
    },
    isLastInArray(array, index) {
      return index === array.length - 1;
    },
    findAverageInBaseObject(arr, objectKey, getAverage = false) {
      if (Array.isArray(arr)) {
        return arr.reduce((acc, val) => {
          if (val.enabled) {
            if (getAverage) {
              return acc + parseFloat(val[objectKey]) / arr.length;
            }
            return acc + parseFloat(val[objectKey]);
          }
          return 0;
        }, 0);
      }
    },
    findAverageInCustomizationObject(arr, objectKey, getAverage = false) {
      if (Array.isArray(arr)) {
        return arr.reduce((acc, val) => {
          if (val.enabled) {
            if (getAverage) {
              return acc + parseInt(val.customization[objectKey]) / arr.length;
            }
            return acc + parseInt(val.customization[objectKey]);
          }
          return 0;
        }, 0);
      }
    },
    handlePageChange(pageToGo) {
      this.$emit('clicked-change-page', pageToGo);
    },
    handleItemsLimit() {
      this.$emit('changed-amount-of-items', this.selectedRowsPerPage);
    },
  },
};
</script>
<style scoped>
.table-container {
  padding: 20px;
}
th {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 15px 15px 0px 5px;
  vertical-align: top;
}

tr:nth-child(even) {
  background-color: rgba(3, 162, 234, 0.1);
}
td {
  font-size: 12px;
  text-align: left;
  padding: 15px 3px 15px 0px;
}
.link {
  color: #00a2ea;
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
}
.sort-arrow-rotated {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.clickable-icon {
  cursor: pointer;
}

.custom-pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  color: #6d797f;
}

.select {
  max-width: 30px;
  font-size: 13px !important;
}

.bordered-bottom {
  border-bottom: solid 1px #6d797f;
  width: 50%;
}

.clickable {
  cursor: pointer;
}
.no-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.center {
  text-align: center;
}
.test #Rectangle {
fill: blue !important;
}

#status-funnel-middle #Rectangle {
    fill: blue !important;
}
</style>