<template>
    <div class="platform-container">
        <div class="column">
            <div class="column-title">
                Remote Address
            </div>
            <p>  {{ platformData.remoteAddress }}</p>
        </div>
        <div class="column">
            <div class="column-title">
                Device
            </div>
            <p>  {{ platformData.device }} </p>
        </div>
        <div class="column">
            <div class="column-title">
                Browser
            </div>
            <p>  {{ platformData.browser }} <b>{{ platformData.browserVersion }} </b></p>
        </div>
        <div class="column">
            <div class="column-title">
                OS
            </div>
            <p>  {{ platformData.os }} <b>{{ platformData.osVersion }} </b></p>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    platformData() {
      return {
        os: this.data.os ?? 'Unknow',
        browser: this.data.browser ?? 'Unknow',
        browserVersion: this.data.browserVersion ?? '',
        osVersion: this.data.osVersion ?? '',
        device: this.data.device ?? 'Unknow',
        remoteAddress: this.data.REMOTE_ADDR ?? 'Unknow',
      };
    },
  }
};
</script>
<style lang="scss" scoped>
.platform-container {
    display: flex;
    align-content: space-around;
}

.column {
  padding: 20px;
  margin-right: 35px;

  p {
    font-size: 12px;
  }
  .column-title {
      font-weight: 600;
  }
}
</style>
