<template>
    <div>
        <table style="border-collapse: collapse">
            <thead>
                <th>Channels</th>
                <th>Audience Size</th>
                <th>Suggested Spend</th>
            </thead>
            <tbody v-if="channelsData.length">
                <tr
                    v-for="(item, index) in channelsData"
                    :key="index"
                    :class="{
                        'final-row': index === channelsData.length - 1,
                    }">
                    <th class="channel-name">
                        {{ fbToMeta(item.channel) }}
                    </th>
                    <td>{{ Math.round(item.audienceSize) | numFormat(numberFormatsAbbreviated.number) }}</td>
                    <td>
                        {{ Math.round(item.suggestedMonthlySpend) | numFormat(numberFormatsAbbreviated.number) }}
                    </td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{{ Math.round(totalAudienceSize) | numFormat(numberFormatsAbbreviated.number) }}</td>
                    <td>{{ Math.round(totalSuggestedMonthlySpend) | numFormat(numberFormats.currencyRound) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { sumBy } from 'lodash';
import getNumberFormats from '@/helpers/numberFormats';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
  props: {
    metrics: Object || Array,
    channel: String,
  },
  data() {
    return {
      numberFormats: getNumberFormats(),
      numberFormatsAbbreviated: getNumberFormats(true),
      fbToMeta,
    };
  },
  computed: {
    channelsData() {
      if (!this.metrics) {
        return [];
      }
      return this.metrics.channelsTotals;
    },
    totalSuggestedMonthlySpend() {
      return sumBy(this.channelsData, 'suggestedMonthlySpend');
    },
    totalAudienceSize() {
      return sumBy(this.channelsData, 'audienceSize');
    },
    channelFilter() {
      return this.channel.toLowerCase();
    },
  },
};
</script>
<style scoped>
table {
  text-align: center;
}
thead th {
  text-transform: uppercase;
}
th {
  font-weight: normal;
  padding: 15px;
}
tbody th {
  text-align: right !important;
}

tbody td {
  color: #00a2ea;
  font-weight: 600;
  font-size: 19px;
}

.final-row {
  border-bottom: solid 1px #dee2e5;
}

.channel-name {
  text-transform: capitalize;
}

.hidden-row {
  display: none;
}
</style>
